var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid", {
        ref: "grid",
        attrs: {
          service: _vm.service,
          route_grid_path: `${_vm.id}/logs`,
          column_formats: {
            status: (val) => val || "",
            participant: (val) =>
              val || "<b class='text-warning'>Não Reconhecido</b>",
            created_at: (val) => _vm.$utils.format.date(val),
          },
        },
        scopedSlots: _vm._u([
          {
            key: "filterZone",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("vs-checkbox", {
                      attrs: { color: "warning" },
                      model: {
                        value: _vm.participantless,
                        callback: function ($$v) {
                          _vm.participantless = $$v
                        },
                        expression: "participantless",
                      },
                    }),
                    _c(
                      "vs-label",
                      {
                        class: [
                          "text-sm select-none font-bold",
                          { "text-warning": _vm.participantless },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t("participante-nao-reconhecido")))]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function (actions) {
              return [
                _c("feather-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.auto",
                      value: {
                        content: "Abrir detalhamento",
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      },
                      expression:
                        "{\n          content: 'Abrir detalhamento',\n          delay: {\n            show: 500,\n            hide: 500\n          }\n        }",
                      modifiers: { auto: true },
                    },
                  ],
                  attrs: {
                    icon: "EyeIcon",
                    svgClasses: "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                  },
                  on: { click: () => _vm.openLogs(actions.row) },
                }),
              ]
            },
          },
        ]),
      }),
      _c("physical-questionnaire-log-viewer", {
        attrs: { service: _vm.service },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }