var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row mt-2" }, [
      _c(
        "div",
        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
        [
          _c("select-suggestion", {
            ref: "select_questionnaire",
            attrs: {
              label: _vm.$t("Questionnaire"),
              "select-class": [
                { "__required-field": !_vm.valid.questionnaire },
              ],
              column: "name,description",
              model: "TeacherContentQuestionnaire",
              appendClearOption: true,
              placeholderText: "Digite o nome do questionário",
            },
            model: {
              value: _vm.questionnaire,
              callback: function ($$v) {
                _vm.questionnaire = $$v
              },
              expression: "questionnaire",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("questionnaire_id"),
                  expression: "errors.has('questionnaire_id')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
          ),
        ],
        1
      ),
    ]),
    _vm.id !== null
      ? _c("div", { staticClass: "vx-row mt-2" }, [
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
            [
              _c(
                "label",
                { staticClass: "text-sm p-1", attrs: { id: "description" } },
                [_vm._v(_vm._s(_vm.$t("instrucoes")))]
              ),
              _c("ComplexEditor", {
                class: [{ "__required-field": !_vm.valid.description }],
                attrs: {
                  questionnaire_id: _vm.id,
                  editor_data: _vm.description,
                  placeholder: _vm.$t("digite-aqui"),
                },
                on: {
                  "update:questionnaire_id": function ($event) {
                    _vm.id = $event
                  },
                  "update:editor_data": function ($event) {
                    _vm.description = $event
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("description"),
                      expression: "errors.has('description')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("description")))]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.id !== null
      ? _c("div", { staticClass: "vx-row mt-2" }, [
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
            [
              _c(
                "label",
                { staticClass: "text-sm p-1", attrs: { id: "header" } },
                [_vm._v(_vm._s(_vm.$t("cabecalho")))]
              ),
              _c("ComplexEditor", {
                class: [{ "__required-field": !_vm.valid.header }],
                attrs: {
                  "model-id": _vm.id,
                  editor_data: _vm.header,
                  placeholder: _vm.$t("digite-aqui"),
                  "image-type": "base64",
                },
                on: {
                  "update:modelId": function ($event) {
                    _vm.id = $event
                  },
                  "update:model-id": function ($event) {
                    _vm.id = $event
                  },
                  "update:editor_data": function ($event) {
                    _vm.header = $event
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("header"),
                      expression: "errors.has('header')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("header")))]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.id !== null
      ? _c("div", { staticClass: "vx-row mt-2" }, [
          _c(
            "div",
            { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
            [
              _c(
                "label",
                { staticClass: "text-sm p-1", attrs: { id: "footer" } },
                [_vm._v(_vm._s(_vm.$t("rodape")))]
              ),
              _c("ComplexEditor", {
                class: [{ "__required-field": !_vm.valid.footer }],
                attrs: {
                  questionnaire_id: _vm.id,
                  editor_data: _vm.footer,
                  placeholder: _vm.$t("digite-aqui"),
                },
                on: {
                  "update:questionnaire_id": function ($event) {
                    _vm.id = $event
                  },
                  "update:editor_data": function ($event) {
                    _vm.footer = $event
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("footer"),
                      expression: "errors.has('footer')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("footer")))]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c(
            "vs-button",
            {
              staticClass: "float-right",
              attrs: { color: "primary", type: "border" },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.$t("action.cancel")))]
          ),
          _c(
            "vs-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "physical_questionnaires.create",
                  expression: "'physical_questionnaires.create'",
                },
              ],
              staticClass: "float-right mr-2",
              attrs: { disabled: !_vm.valid.all },
              on: { click: () => _vm.store(_vm.readModel()) },
            },
            [_vm._v(_vm._s(_vm.$t("action.save")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }