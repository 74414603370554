var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(_vm.userName) + " "),
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title: _vm.$t("correcao-cartao-resposta"),
            "collapse-action": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 pt-4" },
            [
              _c("content-file-upload", {
                attrs: { uploadUrl: _vm.sheetUploadUrl },
                on: { input: _vm.fileUploaded },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "grid grid-cols-3 gap-4 pt-4" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("name")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("email")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("identificador-matricula")))]),
      ]),
      _c("div", { staticClass: "grid grid-cols-3 gap-4 pt-4" }, [
        _c(
          "div",
          [
            _c("select-suggestion", {
              ref: "select-user",
              class: ["flex-grow", { "__required-field": !_vm.valid.name }],
              attrs: {
                column: "name,email",
                model: "User",
                color: "dark",
                appendClearOption: true,
                "prevent-clear": "",
                placeholderText: "Digite o nome ou email do usuário",
                itemTextFormat: (model) => model.name,
              },
              on: { input: _vm.updateNameInput },
              model: {
                value: _vm.user,
                callback: function ($$v) {
                  _vm.user = $$v
                },
                expression: "user",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("vs-input", {
              class: ["w-full", { "__required-field": !_vm.valid.email }],
              attrs: { name: "email" },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("vs-input", {
              class: ["w-full", { "__required-field": !_vm.valid.identifier }],
              attrs: { name: "identifier" },
              model: {
                value: _vm.identifier,
                callback: function ($$v) {
                  _vm.identifier = $$v
                },
                expression: "identifier",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex mt-4 mb-4 justify-end" },
        [
          _c(
            "vs-button",
            {
              attrs: { disabled: !_vm.valid.all, type: "filled", icon: "add" },
              on: {
                click: function ($event) {
                  return _vm.addNewParticipant()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("adicionar-participante")))]
          ),
          _c(
            "vs-button",
            {
              staticClass: "float-right ml-4",
              attrs: {
                type: "border",
                "icon-pack": "feather",
                icon: "icon-file-plus",
              },
              on: {
                click: function ($event) {
                  _vm.showParticipantImportPopup = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("importar")))]
          ),
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("participantes-adicionados"))),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 pt-4" },
        [
          _c("Grid", {
            ref: "grid",
            attrs: {
              service: _vm.service,
              route_grid_path: `${_vm.id}/participants`,
              route_name: "participants",
              multipleSelect: true,
              selectAllCallback: _vm.selectAll,
            },
            on: { changedSelection: _vm.changedSelection },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (actions) {
                  return [
                    _c("feather-icon", {
                      attrs: {
                        icon: "Trash2Icon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                      },
                      on: { click: () => _vm.removeParticipant(actions.row) },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Abrir detalhamento",
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          },
                          expression:
                            "{\n            content: 'Abrir detalhamento',\n            delay: {\n              show: 500,\n              hide: 500\n            }\n          }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "EyeIcon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                      },
                      on: { click: () => _vm.openLogs(actions.row) },
                    }),
                    _vm._.get(actions, "row.answer_id") !== null
                      ? _c("feather-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.auto",
                              value: {
                                content: "Abrir correção manual",
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              },
                              expression:
                                "{\n            content: 'Abrir correção manual',\n            delay: {\n              show: 500,\n              hide: 500\n            }\n          }",
                              modifiers: { auto: true },
                            },
                          ],
                          attrs: {
                            icon: "Edit3Icon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                          },
                          on: {
                            click: () => _vm.openManualCorrection(actions.row),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass: "__position-buttons flex w-full gap-2 justify-start",
            },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.auto",
                      value: {
                        content:
                          "Solicitar ao sistema o caderno de prova dos participantes selecionados;",
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      },
                      expression:
                        "{\n          content: 'Solicitar ao sistema o caderno de prova dos participantes selecionados;',\n          delay: {\n            show: 500,\n            hide: 500\n          }\n        }",
                      modifiers: { auto: true },
                    },
                  ],
                  attrs: {
                    disabled: _vm.selectedParticipants.length === 0,
                    color: "primary",
                    type: "border",
                    icon: "file_download",
                  },
                  on: { click: _vm.downloadQuestionnaireSheets },
                },
                [_vm._v(" " + _vm._s(_vm.$t("baixar-prova")) + " ")]
              ),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.auto",
                      value: {
                        content:
                          "Solicitar ao sistema a folha de resposta dos participantes selecionados;",
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      },
                      expression:
                        "{\n          content: 'Solicitar ao sistema a folha de resposta dos participantes selecionados;',\n          delay: {\n            show: 500,\n            hide: 500\n          }\n        }",
                      modifiers: { auto: true },
                    },
                  ],
                  attrs: {
                    disabled: _vm.selectedParticipants.length === 0,
                    color: "success",
                    type: "border",
                    icon: "file_download",
                  },
                  on: { click: _vm.downloadAnswerSheets },
                },
                [_vm._v(" " + _vm._s(_vm.$t("baixar-cartao-resposta")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: _vm.$t("importar-participantes"),
            active: _vm.showParticipantImportPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showParticipantImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c("ImportExcel", {
                attrs: {
                  onSuccess: _vm.onParticipantSuccess,
                  templateFile:
                    "educatena-modelo-importacao-participantes.xlsx",
                  templateMessage: _vm.$t(
                    "clique-aqui-baixar-modelo-participantes"
                  ),
                  accept: ".xlsx, .xls",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("correcao-manual"),
            active: _vm.showManualCorrectionPopup,
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.showManualCorrectionPopup = $event
            },
          },
        },
        [
          _vm.manualCorrection.participantId
            ? _c("physical-questionnaire-answer-correction", {
                attrs: { "participant-id": _vm.manualCorrection.participantId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("participant-logs-viewer", { attrs: { service: _vm.service } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }