var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      attrs: { data: _vm.messages, sst: true, "current-sort-type": "asc" },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(data, function (tr, i) {
              return _c(
                "vs-tr",
                { key: data[i], class: `_log-message-viewer_${i}` },
                [
                  _c("vs-td", { staticClass: "break-all p-1 pl-4" }, [
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.formatValue(_vm._.get(tr, "text")),
                            expression: "formatValue(_.get(tr, 'text'))",
                          },
                        ],
                      }),
                    ]),
                    _c("div", { staticClass: "w-full text-sm" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$utils.format.dateWithFormat(
                                _vm._.get(tr, "time"),
                                "DD/MM/YYYY - kk:mm:ss"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", { attrs: { "sort-key": "text" } }, [
            _vm._v(_vm._s(_vm.$t("mensagem"))),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }