var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticStyle: { "z-index": "54000" },
          attrs: { title: _vm.$t("envio"), active: _vm.showLog },
          on: {
            "update:active": function ($event) {
              _vm.showLog = $event
            },
          },
        },
        [
          _vm.log
            ? _c(
                "div",
                { staticClass: "flex flex-col gap-4" },
                [
                  _vm.log
                    ? _c("log-messages", { attrs: { messages: _vm.messages } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }